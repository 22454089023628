import { Component } from '@angular/core';
import { AuthService } from '../app/services/auth.service';
import { environment } from 'src/environments/environment';
import { InfoCollectionService } from './services/info-collection.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [];

  public adminPages = [
    { title: 'Menú administrativo', url: '/admin/menu', icon: 'cube' },
    //{ title: 'Información de compañia', url: '/admin/company-info', icon: 'map' },
    //{ title: 'Términos y condiciones', url: '/admin/terms-and-cond', icon: 'reader' },
    //{ title: 'Certificados de garantia', url: '/admin/warranty-certificates', icon: 'ribbon' },
    
    { title: 'Usuarios', url: '/admin/users', icon: 'body' },
    { title: 'Roles de usuario', url: '/admin/user-roles', icon: 'pricetags' },
  ];
  public miscPages = [
    { title: 'Ajustes', url: '/settings', icon: 'cog' },
  ];
  
  public userLoggedIn:boolean = false;
  public currentUser:any = {}

  constructor(private auth:AuthService, private collectServ:InfoCollectionService) {
    this.verificarModulos();
    this.collectUserInfo();
    this.auth.validateUserRole();

    this.userLoggedIn = this.auth.isUserLoggedIn();
    if (this.userLoggedIn) {
      this.currentUser = this.auth.whoami();
    }
  }

  logout(){
    this.auth.logout();
    this.userLoggedIn = this.auth.isUserLoggedIn();
  }

  verificarModulos(){
    if (environment.available_modules.crm) {
      this.appPages.push(
        { title: 'Menú principal', url: '/menu-principal', icon: 'home' },
        { title: 'Clientes', url: '/clientes', icon: 'people' },
        { title: 'Vehículos', url: '/control-vehiculos', icon: 'car-sport' },
        { title: 'Citas', url: '/citas', icon: 'calendar-number' },
        { title: 'Recepciones', url: '/recepciones', icon: 'receipt' },
      );
    }
    if (environment.available_modules.production) {
      this.appPages.push(
        { title: 'Cotizaciones', url: '/cotizaciones', icon: 'document-text' },
        { title: 'Trabajos aseguradoras', url: '/insurance-jobs', icon: 'file-tray-stacked' },
        { title: 'Ordenes de trabajo', url: '/workorders', icon: 'construct' },
      );
    }

    if (environment.available_modules.inventory) {
      this.appPages.push(
        { title: 'Inventario', url: '/inventarios', icon: 'albums' },
      );
    }

    if (environment.available_modules.sales) {
      this.appPages.push(
        { title: 'Ventas', url: '/pagos', icon: 'wallet' },
      );
    }

    this.appPages.push({ title: 'Módulos', url: '/admin/modules', icon: 'apps' },);
    
  }

  collectUserInfo(){
    this.collectServ.collectEnv({info:'no info'}).then((env_info:any)=>{
      console.log(env_info);
    })
  }


}
