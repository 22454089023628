import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';

import { AlertController,LoadingController,ActionSheetController } from '@ionic/angular';
import { AlertsManagementServiceService } from 'src/app/services/alerts-management-service.service';
import { AuthService } from 'src/app/services/auth.service';
import { CommentsService } from 'src/app/services/comments.service';
import { DateService } from 'src/app/services/date.service';
import { UploadFileService } from 'src/app/services/upload_files.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent  implements OnInit {

  @Input() module_name: string;
  @Input() record_id : string;

  @ViewChild("uploader") uploader: ElementRef<HTMLInputElement>;
  

  comments: any[] = [];
  user_id:any;

  newComment:any = {
    body: "",
  }

  interval: any;

  constructor(private commentServ: CommentsService, private loadingCtrl: LoadingController, 
    private dateServ: DateService, private fileServ:UploadFileService, private alert:AlertController,
    private actionSheetController: ActionSheetController, private auth: AuthService, private alertMgmt:AlertsManagementServiceService
   ) { }

  ngOnInit() {

    console.log(this.module_name, this.record_id);
    this.getAllRecordComments(this.module_name, this.record_id);


    this.interval = setInterval(()=>{this.getAllRecordComments(this.module_name, this.record_id)}, 60000);

    this.user_id = this.auth.whoami().user.id;
    console.log(this.user_id);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    clearInterval(this.interval);
    console.info('Removing interval');
  }

  

  async getAllRecordComments(module, record){
    let loading = await this.loadingCtrl.create({
      message: 'Cargando comentarios',
    });
    //await loading.present();

    this.commentServ.getAllCommentsOnModule(module, record)
    .subscribe({
      next: (result:any)=>{
        const data:any = structuredClone(result.data);
        this.comments = data;

        this.comments.forEach((comment)=>{
          comment.attributes.createdAt = this.dateServ.convertDate(comment.attributes.createdAt, 'America/Costa_Rica')
        })
      },
      async error(error) {
        console.log(error);
      },
      complete(){
        loading.dismiss();
      }

    })
  }

  async createComment(){
    let loading = await this.loadingCtrl.create({
      message: 'Creando comentario...',
    });
    await loading.present();
    let data = {
      comment_body: this.newComment.body,
      module_name: this.module_name,
      record_id: this.record_id,
    };

    this.commentServ.addComment(data).subscribe({
      next:async (result:any) => {

        console.log(result);

        if (this.filesToBeLoad.length != 0) {
          await this.cargarDocumentos(result.data.id).then(()=>{
            this.getAllRecordComments(this.module_name, this.record_id);
            this.newComment.body = "";
            this.filesToBeLoad = [];
          })
        }else{
          this.getAllRecordComments(this.module_name, this.record_id);
          this.newComment.body = "";
          this.filesToBeLoad = [];
        }
      },
      async error(error) {
        console.log(error);
      },
      complete(){
        loading.dismiss();
      }
    })
  }

  async deleteComment(comment_id){
    console.info(comment_id);

    const alert = await this.alert.create({
      header: '¿Desea eliminar este comentario?',
      subHeader: 'Eliminar comentario',
      message: 'Esta acción no se puede deshacer.',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: () => {
            console.log('Acción cancelada');
          },
        },
        {
          text: 'Sí, eliminar',
          role: 'confirm',
          handler: () => {
            console.log('Acción confirmada');
            this.commentServ.removeComment(comment_id).subscribe({
              next:(value) =>{
                this.getAllRecordComments(this.module_name, this.record_id);
              },
              error:(err) => {
                this.alertMgmt.mostrarAlertError();
              },
              complete: () => {}
            })
          },
        },
      ],
    });

    await alert.present();

  }

  popFileChooser(): void {
    this.uploader.nativeElement.click();
  }

  clickAttachment(url:string){
    window.open(url);
    console.info('url opened', url)
  }

  async ClickOptionMenu(comment_id){
    const actionSheet = await this.actionSheetController.create({
      header: 'Acciones',
      buttons: [
        {
          text: 'Eliminar comentario',
          role: 'destructive',
          data: {
            action: 'delete',
          },
          handler: () => {
            this.deleteComment(comment_id);
          },
        }/* ,
        {
          text: 'Compartir',
          data: {
            action: 'share',
          },
        } */,
        {
          text: 'Cancelar',
          role: 'cancel',
          data: {
            action: 'cancel',
          },
        },
      ],
    });

    await actionSheet.present();
  }

  filesToBeLoad: any[] = [];
  async ListarDocumentos(event, field){
    console.log(event, field);

    for (let item = 0; item < event.target.files.length; item++) {
          const current_file = event.target.files[item];

          this.filesToBeLoad.push(current_file);
        //formData.append("refId",`${this.customer.id}`);
        //formData.append("path",`${environment.instance_name}/comments/${this.customer.id}`)
    }

    console.log(this.filesToBeLoad);
  }

  async retirarDocumento(id){

    const alert = await this.alert.create({
      header: '¿Desea eliminar este documento del formulario?',
      subHeader: 'Eliminar documento',
      message: 'Esta acción no se puede deshacer.',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: () => {
            console.log('Acción cancelada');
          },
        },
        {
          text: 'Sí, eliminar',
          role: 'confirm',
          handler: () => {
            console.log('Acción confirmada');
            console.log(id);
            this.filesToBeLoad.splice(id,1);
          },
        },
      ],
    });

    await alert.present();
    
  }

  async cargarDocumentos(id){
    console.log(event);

    let loading = await this.loadingCtrl.create({
      message: 'Cargando documentos',
      spinner: 'dots',
      translucent: true
    });
    await loading.present();

    for (let item = 0; item < this.filesToBeLoad.length; item++) {
      const current_file:File = this.filesToBeLoad[item];
      let formData:FormData = new FormData();

      formData.append("files",current_file);
      formData.append("path",`${environment.instance_name}/comments/${id}`)
      formData.append("ref",`api::comment.comment`);
      formData.append("refId",`${id}`);
      formData.append("field",`attached_files`);

      await this.fileServ.uploadFile(formData).toPromise()
      .then(async (data)=>{
        console.log(data);
          console.log(
            data.type == 1 ? (data.loaded / data.total) * 100 : "Cargando"
          );
          if (data.type == 0) {
            loading.message = "Iniciando carga de documento..."
          }
          if (data.type == 1) {
            loading.message = `Cargando archivo ${Math.round((data.loaded / data.total) * 100)}% ...` 
          }
          if (data.type == 3) {
            loading.message = `Completando carga de archivo...` 
          }
      })
      .catch(async (err)=>{
        console.log(err);
          let error_alert = await this.alert.create({
            header: 'No se logró cargar el documento',
            subHeader: ``,
            message: `No se pudo cargar el documento`,
            buttons: ['OK'],
          });
          loading.dismiss();
          await error_alert.present();
      })
      .finally(()=>{
        //this.getInformation();
        loading.dismiss();
      });
    }

  }
}
